import { Col, Row } from "antd";
import {
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_SERVICE,
  ROUTE_CONTACT_US_UNREGISTER,
  ROUTE_HOME,
} from "navigation/CONSTANTS";
import React from "react";
import { Link } from "react-router-dom";
import "./LandingHeader.css";
import logoImage from "assets/images/logo.png";
import { useSelector } from "react-redux";
const LandingHeader = () => {
  const onClickStoreUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const appDataStore = useSelector((x) => x.appDataStore);
  const { width } = appDataStore;
  if (width < 1000) {
    return <div />;
  }
  return (
    <div className="landing-header">
      <Row align={"middle"} gutter={[16, 16]}>
        <Col xs={0} md={5}>
          <img src={logoImage} alt="logoimage" style={{ maxWidth: "70%", height: 70 }}
            className="landing-app-download-image custom-button"
            onClick={() => {
              onClickStoreUrl(
                "https://waitindustries.com/"
              );
            }}
          />
        </Col>
        <Col xs={0} md={12}>
          <Row justify={"space-between"}>
            <Col>
              <div className="landing-header-category-item">
                <Link to={ROUTE_HOME}>Home</Link>
              </div>
            </Col>
            {/* <Col>
              <div className="landing-header-category-item">
                <Link to={ROUTE_PRIVACY_POLICY}>Blog</Link>
              </div>
            </Col> */}
            <Col>
              <div className="landing-header-category-item">
                <Link to={ROUTE_TERMS_OF_SERVICE}>FAQ</Link>
              </div>
            </Col>
            <Col>
              <div className="landing-header-category-item">
                <Link to={ROUTE_CONTACT_US_UNREGISTER}>Contact Us</Link>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={1}></Col>
        <Col xs={0} md={6}>
          <Row gutter={[8, 8]} justify="space-around">
            <Col xs={8}>
              <img
                className="landing-app-download-image custom-button"
                src="/assets/images/about-us/googleplay.png"
                onClick={() => {
                  onClickStoreUrl(
                    "https://play.google.com/store/apps/details?"
                  );
                }}
                alt="landing-download-app-image"
              />
            </Col>
            {/* <Col xs={8}>
              <img
                className="landing-app-download-image custom-button"
                src="/assets/images/about-us/appstore.png"
                onClick={() => {
                  onClickStoreUrl(
                    "https://apps.apple.com/us/app/"
                  );
                }}
                alt="landing-download-app-image"
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LandingHeader;
