import React from "react";
import { Col, Row } from "antd";
const HeaderWidget = () => {
  return (
    <div className="login-part-header-container">
      <Row style={{ width: "100%" }}>
        <Col xs={24} md={12}>
          <div style={{ maxWidth: 500 }}>
            <div className="landing-header2-text">
            Welcome to the Cargo
            Trailer Ramp Door Spring
            Sizing System
            </div>
          </div>
        </Col>
        <Col xs={24} md={12}> 
          <div
            className="landing-description1-text"
          >
            1. Please enter the requested information into the form below. <br />
            2. Click on the Recommend button. <br />
            3. The best options will be listed below <br/>
            4. Select the spring you wish to order and you will be directed to ordering information. <br/>
            5. If we do not have a standard spring that meets your needs, please click Contact Us.  <br/>
            The information from the form will be sent to our technicians, who will assist you with your
            application.
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default HeaderWidget;
