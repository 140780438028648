// @flow strict

import CollapsedContent from "components/CollapsedContent/CollapsedContent";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import { ROUTE_TERMS_OF_SERVICE } from "navigation/CONSTANTS";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import "./PrivacyPage.css";
export const PrivacyPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  const mailToWidget = (emailAddress) => (
    <a href={`mailto://${emailAddress}`}>
      <font color="blue">
        <u> {emailAddress}</u>
      </font>
    </a>
  );
  return (
    <TempTopMenuWidget>
      <MetaTags>
        <title>Spring | BLOG</title>
        <meta
          name="description"
          content="Read the Blog for Spring. Get the best deals online.  Our Email address: legal@spring.com"
        />
        <meta name="keywords" content="Shoppers Online, Best Deals" />
      </MetaTags>
      <div>
        <div className="privacy-page">
          <div className="privacy-page-title">BLOG</div>
          <CommonDivWidget>
            <div className="privacy-page-description">
              <p>Testing Blog</p>
            </div>
          </CommonDivWidget>
          <p className="bottom-text">For more information on any of our ramp door spring systems, or for assistance with custom needs, contact at 574-294-3988 or send us an email at&nbsp;<a href="mailto: info@rampdoorsprings.com"> info@rampdoorsprings.com</a>.</p>

        </div>
      </div>
    </TempTopMenuWidget>
  );
};

export default PrivacyPage;
