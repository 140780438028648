import { googleKey } from "config/CONSTANTS";

//export const BASE_URL               = "http://192.168.0.69/SpringCalcSystem";
export const BASE_URL               = "https://rampdoorsprings.com/backend/SpringCalcSystem";

//User backend base url
export const BASE_URL_USER                  = BASE_URL + "/user";

export const urlGetInternalUseForUser       = BASE_URL_USER + "/DashBoard/getInternalUse";
export const urlGetPartListForUser          = BASE_URL_USER + "/DashBoard/getPartList";
export const urlCreateOrder                 = BASE_URL_USER + "/DashBoard/createOrder";

export const urlContactUs                   = BASE_URL_USER + "/DashBoard/contactUs";
export const urlUserLogin                   = BASE_URL_USER + "/login";

