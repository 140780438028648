import RadiusModal from "components/RadiusModal/RadiusModal";
import React from "react";
import { confirmable } from "react-confirm";
import "./InformDialog.css";
const InformDialog = (props) => {
  const { show, proceed } = props;
  const { title, content, canEscape } = props;
  return (
    <RadiusModal
      show={show}
      title={title}
      isConfirmDlg={true}
      canEscape={canEscape}
      onClickOK={() => {
        proceed(true);
      }}
      onClickCancel={() => {
        proceed(false);
      }}
    >
      {content}
    </RadiusModal>
  );
};

export default confirmable(InformDialog);
