import React, { useState } from "react";

import logoImage from "assets/images/logo.png";
import { Col, Input, Row, Divider, BackTop } from "antd";
import { Link } from "react-router-dom";
import {
  ROUTE_ABOUT_US,
  ROUTE_CONTACT_US_UNREGISTER,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_SERVICE,
} from "navigation/CONSTANTS";
import MobileAppListWidget from "components/MobileAppListWidget/MobileAppListWidget";
import { showTextInputDialog } from "components/CommonDialogs/TextInputDialog/showTextInputDialog";
import { validateEmail } from "services/commonService";
import { showInformDialog } from "components/CommonDialogs/InformDialog/showInformDialog";
import { apiContactUs } from "services/contactService";
const PageCategoryWidget = () => {
  const onClickStoreUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [email, setEmail] = useState("");
  const onClickContact = async () => {
    if (validateEmail(email) == false) {
      await showInformDialog({
        title: "",
        canEscape: true,
        content: (
          <div style={{ fontSize: 18, textAlign: "center" }}>
            Please type valid email.
          </div>
        ),
      });
      return;
    }
    const text = await showTextInputDialog({
      title: <div>Please share your opinion with us</div>,
      isLongText: true,
      placeholder: "Type here",
      content: "",
    });
    if (text == false) return;
    apiContactUs({
      full_name: "Unregistered User",
      contact_info: email,
      subject: "From Landing Page",
      message: text,
    });
    await showInformDialog({
      title: "",
      canEscape: true,
      content: (
        <div style={{ fontSize: 18, textAlign: "center" }}>
          Thank you for your feedback.
        </div>
      ),
    });

    setEmail("");
  };
  return (
    <div style={{ padding: "5%" }}>
      <div style={{ marginTop: 30 }}>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={16}>
            <div>
              <img
                src={logoImage}
                alt="logoimage"
                style={{ maxWidth: "300px", height: 80 }}
                className="landing-app-download-image custom-button"
                onClick={() => {
                  onClickStoreUrl(
                    "https://waitindustries.com/"
                  );
                }}
              />
            </div>
            <Row gutter={[8, 8]} style={{ marginTop: 30 }}>
              <Col xs={24} md={12} lg={8}>
                <div
                  className="landing-header2-text"
                  style={{ fontSize: 20, color: "var(--blackColor)" }}
                >
                  About SpringCalcSystem
                </div>
                <div className="page-category-item">
                  <Link to={ROUTE_CONTACT_US_UNREGISTER}>Contact Us</Link>
                </div>
                {/* <div className="page-category-item">
                  <Link to={ROUTE_ABOUT_US}>Our Services</Link>
                </div> */}
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={8}>
            <div style={{ marginTop: 10 }}>
              <Row>
                <Col>
                  <div
                    className="landing-header2-text"
                    style={{ fontSize: 20, color: "var(--blackColor)" }}
                  >
                    Download Mobile Apps
                    <MobileAppListWidget />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Divider type={"horizontal"} className="page-category-divider" />
        <Row gutter={[32, 32]}>
          <Col>
            <div className="footer-page-category-item">
              <Link to={ROUTE_HOME}>Home</Link>
            </div>
          </Col>
          {/* <Col>
            <div className="footer-page-category-item">
              <Link to={ROUTE_PRIVACY_POLICY}>Blog</Link>
            </div>
          </Col> */}
          <Col>
            <div className="footer-page-category-item">
              <Link to={ROUTE_TERMS_OF_SERVICE}>FAQ</Link>
            </div>
          </Col>
          <Col>
            <div className="footer-page-category-item">
              <Link to={ROUTE_CONTACT_US_UNREGISTER}>Contact us</Link>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ textAlign: "center", marginTop: 20 }}>
        2021 All rights reserved, www.rampdoorsprings.com
      </div>
      <BackTop />
    </div>
  );
};

export default PageCategoryWidget;
