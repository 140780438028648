import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React from "react";
import { MetaTags } from "react-meta-tags";
import HeaderWidget from "./HeaderWidget";
import HomePage from "./HomePage";

const DashboardPage = () => {
  
  return (
    <div>
      <TempTopMenuWidget>
      <MetaTags>
        <title>Spring | Home</title>
        <meta
          name="description"
          content="Welcome to Spring. Read the terms of services  to understand how they apply to your use of our website."
        />
        <meta name="keywords" content="Best Deals" />
      </MetaTags>
        <HeaderWidget />
        <HomePage />
      </TempTopMenuWidget>
    </div>
  );
};

export default DashboardPage;
