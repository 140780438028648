import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import "./AddPartDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { Col, Input, message, Row, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";

const { TextArea } = Input;

function AddPartDialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({});
  const [errorField, setErrorField] = useState([]);

  
  
  useEffect(() => {
    if (partInfo !== undefined) {
      setSelPart({ ...partInfo });
    } else {
      setSelPart({});
    }
  }, [partInfo]);

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selPart, "name", errorList);
    errorList = isEmpty(selPart, "email", errorList);
    errorList = isEmpty(selPart, "phone_number", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-part-dlg"
      onOk={async () => {

        if (isEditing === false && (await validateFields()) === false) return;
        setSelPart({ ...selPart });
        proceed({
          ...selPart,
        });
      }}
    >
      <div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Part No*</div>
          <ProductInputNumber
            disabled={true}
            min={"0"}
            field={"part_no"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Your Name*</div>
          <ProductDescriptionInput
            placeholder="Enter your name"
            field={"name"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <div>Your Email*</div>
          <ProductDescriptionInput
            placeholder="Enter your email"
            field={"email"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <div>Phone Number*</div>
          <ProductDescriptionInput
            placeholder="Enter phone number"
            field={"phone_number"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <div>Address(optional)</div>
          <ProductDescriptionInput
            placeholder="Enter your address"
            field={"address"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <div>Company Name(optional)</div>
          <ProductDescriptionInput
            placeholder="Enter company name"
            field={"company_name"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>

    </div>
    </Modal>
  );
}

export default confirmable(AddPartDialog)
