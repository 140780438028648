import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import "./RecommendOptionDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import { Col, Input, Radio, Row, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";

const { TextArea } = Input;

function RecommendOptionDialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({spring_qty:'3'});

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-recommend-option-dlg"
      onOk={async () => {
        setSelPart({ ...selPart });
        proceed({
          ...selPart,
        });
      }}
    >
      <div style={{marginLeft:5}}>

        <Radio.Group
          style={{ textAlign: "left", color: "var(--blackColor)" }}
          onChange={(e) =>
            setSelPart({ ...selPart, spring_qty: e.target.value })
          }
          value={selPart['spring_qty']}
        >
          <Row align={"middle"} gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Radio style={{ color: 'var(--blackColor)' }} value={'1'}>Only 1 Spring System</Radio>
            </Col>
          </Row>
          <Row align={"middle"} gutter={16} style={{marginTop:10}}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Radio style={{ color: 'var(--blackColor)' }} value={'2'}>Only 2 Spring System</Radio>
            </Col>
          </Row>
          <Row align={"middle"} gutter={16} style={{marginTop:10}}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Radio style={{ color: 'var(--blackColor)' }} value={'3'}>Both 1 And 2 Spring System</Radio>
            </Col>
          </Row>
        </Radio.Group>


      </div>
    </Modal >
  );
}

export default confirmable(RecommendOptionDialog)
