// @flow strict

import CollapsedContent from "components/CollapsedContent/CollapsedContent";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import TempTopMenuWidget from "layouts/TempTopMenuWidget/TempTopMenuWidget";
import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import "./TermsPage.css";
function TermsPage(props) {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, []);
  const mailToWidget = (emailAddress) => (
    <a href={`mailto://${emailAddress}`}>
      <font>
        <u>{emailAddress}</u>
      </font>
    </a>
  );
  return (
    <TempTopMenuWidget>
      <MetaTags>
        <title>Spring | FAQ</title>
        <meta
          name="description"
          content="Welcome to Spring. Read the FAQ  to understand how they apply to your use of our website."
        />
        <meta name="keywords" content="Best Deals" />
      </MetaTags>
      <div className="terms-page-wrapper">
        <div className="terms-page">
          <div className="terms-page-title"> Ramp Door Spring Systems FAQ</div>
          <CommonDivWidget>
            <div><strong><span style={{ color: "red" }}>Important Note:</span></strong></div>
            <div><span style={{ color: "red" }}>Spring systems can contain a significant amount of stored energy and can be dangerous.&nbsp; Serious injury can result if the spring system is improperly adjusted, modified or otherwise altered.&nbsp; If you are not mechanically inclined or unsure of the dangers involved, please do not attempt to work on your system.&nbsp; A local overhead garage door dealer can be an excellent resource for working on these systems.</span></div>
            <br />
            <h3>Installation Instructions:</h3>
            <p style={{ paddingLeft: 30 }}><a href="/data/Spring-System-Dual-InstallationGuide.pdf" target="_blank" rel="noopener">Ramp Door Spring System – Dual Spring – Installation Guide</a></p>
            <p style={{ paddingLeft: 30 }}><a href="/data/Spring-System-Single-InstallationGuide.pdf" target="_blank" rel="noopener">Ramp Door Spring System – Single Spring – Installation Guide</a></p>

            <div className="terms-page-title">
              <CollapsedContent title={"Three Main Spring System Configurations"}>
                <div className="terms-page-description">
                  <p style={{ paddingLeft: 30 }}><strong>Single Spring System</strong> – A single spring on the torsion rod with lift cables at each end to lift the door.&nbsp; If the spring breaks, there is no backup to reduce the door weight.</p>
                  <p style={{ paddingLeft: 30 }}><a href="/assets/images/Spring-System-Single.png"><img className="alignnone wp-image-435 size-full" src="/assets/images/Spring-System-Single.png" alt="" width="381" height="129" srcSet="/assets/images/Spring-System-Single.png 381w, /assets/images/Spring-System-Single.png 300w, h/assets/images/Spring-System-Single.png 380w" sizes="(max-width: 381px) 100vw, 381px" /></a></p>
                  <p style={{ paddingLeft: 30 }}><strong>Double Spring System</strong> – Two springs on the torsion rod with lift cables at each end to lift the door.&nbsp; &nbsp;The double spring provides a safety feature.&nbsp; If one spring breaks, the other spring can counterbalance half the door weight.</p>
                  <p style={{ paddingLeft: 30 }}><a href="/assets/images/Spring-System-Double.png"><img loading="lazy" className="alignnone size-full wp-image-434" src="/assets/images/Spring-System-Double.png" alt="" width="362" height="131" srcSet="/assets/images/Spring-System-Double.png 362w, /assets/images/Spring-System-Double.png 362w 300w" sizes="(max-width: 362px) 100vw, 362px" /></a></p>
                  <p style={{ paddingLeft: 30 }}><strong>Single-Single Spring System</strong> –&nbsp;A single spring on the torsion rod with a lift cable at one end to lift the door.&nbsp; It only uses one cable to lift the door.&nbsp; This systems is often utilized in snowmobile trailers to reduce the potential for catching a ski in the cable.&nbsp; Also, if the spring breaks, there is no backup to reduce the door weight.</p>
                  <p style={{ paddingLeft: 30 }}><a href="/assets/images/Spring-System-SingleSingle.png"><img loading="lazy" className="alignnone size-full wp-image-440" src="/assets/images/Spring-System-SingleSingle.png" alt="" width="381" height="129" srcSet="/assets/images/Spring-System-SingleSingle.png 381w, /assets/images/Spring-System-SingleSingle.png 300w, /assets/images/Spring-System-SingleSingle.png 380w" sizes="(max-width: 381px) 100vw, 381px" /></a></p>
                </div>
              </CollapsedContent>
              <CollapsedContent title={"How to Measure for a Ramp Door Spring Assist System?"}>
                <div className="terms-page-description"><h3>Critical measurements:</h3>
                  <p><strong>Springing weight</strong> – Weight of the ramp at the beginning of the spring cycle. Determined by placing a scale under the open door with no spring system attached.<a href="/assets/images/CargoTrailerRampSketch1024.png"><img className="alignright" src="/assets/images/CargoTrailerRampSketch1024.png" alt="" width="341" height="276" srcSet="/assets/images/CargoTrailerRampSketch1024.png 1024w, /assets/images/CargoTrailerRampSketch1024.png 300w, /assets/images/CargoTrailerRampSketch1024.png 768w" sizes="(max-width: 341px) 100vw, 341px" /></a></p>
                  <p><strong>Door Height</strong> – It is also helpful to know the distance from the top of the door to the end of the open door. This is an easy check to be sure the cable will be long enough. Standard cable lengths are 150”. Lengths of 110”, 125”, and 174” are also available and can be substituted in any standard system.&nbsp; Standard M-3 drums require 13” of cable to remain on the drum when the door is open. This is a safety precaution that insures that the cable does not disconnect from the drum.</p>
                  <p><strong>Header Width</strong> – Width of available headroom inside of the trailer. This determines the length of tube in the spring assist.</p>
                  <p><strong>Frame material of trailer</strong> – Spring assist systems are built with either steel or aluminum weld brackets&nbsp;that attach to the header above the door frame.</p>
                  <p><strong>Ramp door hinge style</strong> – M-3 spring assist systems are designed to be used on doors with pipe-style&nbsp;hinges attaching the ramp door to the trailer frame. Spring-type hinges should not be used&nbsp;with M-3 spring assists as they alter the physics of the door and you will not achieve satisfactory lift.</p>
                  <p>Please use the following <a href="/data/M-3-Spring-System-Spec-Template.pdf">M-3 Spring System Spec Template</a> to help us size your system.&nbsp; Fill in the relevant information and send it to us.&nbsp; We will respond with a system that will meet your needs.</p>
                </div>
              </CollapsedContent>
              <CollapsedContent title={"Altering the Length of the Tube On Spring Systems"}>
                <div className="terms-page-description"><p>The tube on spring systems can be shortened by cutting the tube with a hacksaw and re-positioning the component parts on the resulting narrower tube.</p>
                  <h3>Here’s how to determine the minimum possible tube length:</h3>
                  <p>The springs will grow in length as they are wound so it is possible to cut a tube too short.&nbsp; Here is the method to determine how much can be removed and still have enough&nbsp; tube to wind the system properly.</p>
                  <ul>
                    <li>For drums and end bearing assemblies allow 10 inches on each system.</li>
                    <li>The length of the coned spring is last number in a raw spring part number description.&nbsp; In other words a RH 120# system uses the spring R19220021WO.&nbsp; This part number means a right hand wound spring made of .192 wire that is 2.00 inches in diameter, and is 21 inches long.&nbsp; The length you need is the 21 inches.</li>
                    <li>Clever cones on the spring add 1.5” to each standard spring and 2” to each oversize spring.</li>
                    <li>The length the spring “grows” when wound by the number of turns times the wire size for each spring.</li>
                  </ul>
                  <h3>Some examples:</h3>
                  <p><strong>160# dual spring 96” system:</strong></p>
                  <p style={{ paddingLeft: 30 }}>10 inches for drums and end bearings + (27.5” + 1.5” (spring and cones)) x 2 since it has two springs + (0.218 wire x 10 turns (length spring grows)) x 2 since it is a dual = 72.36”</p>
                  <p style={{ paddingLeft: 30 }}>This tubecan be cut to 75” and still function properly.</p>
                  <p><strong>50# 66” single-single system:</strong></p>
                  <p style={{ paddingLeft: 30 }}>10 inches for drums and end bearings + 22” + 1.5” (spring and cone – do not double as this is a single-spring system) + (0.187 wire x 10 turns) (length spring grows – again do not double since this is a single) = 35.37”</p>
                  <p style={{ paddingLeft: 30 }}>This tube can be cut to&nbsp; 37”&nbsp; and still work correctly.</p>
                  <p><strong>250# 96” dual spring system:</strong></p>
                  <p style={{ paddingLeft: 30 }}>10 inches for drums and end bearings + (28.5” + 2” (spring and oversize cone)) x 2 since it is a dual system + (0.250 wire x 10 turns (length spring grows)) x 2 since it is a dual system = 76”</p>
                  <p style={{ paddingLeft: 30 }}>This tube can be cut to 78” and still function properly.</p>
                </div>
              </CollapsedContent>
              <CollapsedContent title={"Cable Jumps Off Drum"}>
                <div className="terms-page-description"><p>Customer has installed spring assist system on a “used” trailer.&nbsp; After winding the spring the door opens and closes but the cable on one side “jumps” off of the cable spool, pulley, or drum.</p>
                  <p>Things to check – cable anchor brackets are the same distance down on both sides of the door.</p>
                  <p>Suggested fix – Close the door and secure it.&nbsp; Go inside the trailer and loosen the set screw on the cable drum that the cable is coming off of.&nbsp; IMPORTANT &nbsp;–&nbsp; BE SURE THE DOOR IS CLOSED BEFORE YOU MESS WITH THE CABLE DRUM.&nbsp; LOOSEN THE SET SCREWS ON THE CABLE DRUM NOT THE WOUND SPRING OR YOU MAY BE HURT.&nbsp; After loosening the set screws wind the cable up on the drum until it is finger tight.&nbsp; Tighten the set screws.&nbsp; Open and close the door a couple of times to set the cable into the grooves of the drum.&nbsp; This should correct the problem.</p>
                  <p>If it does not – either the door is racked or the rear hoop frame of the trailer is racked.&nbsp; Both drums are the same diameter and they move on a common shaft at the same speed.&nbsp; Since both cables are the same length, the “take up” is the same on both sides.&nbsp; The only variable is the squareness of the door and trailer frames.&nbsp; Racking of frame components is not a situation M-3 can correct with the spring system.</p>
                </div>
              </CollapsedContent>
              <CollapsedContent title={"Positioning the Cable Drums"}>
                <div className="terms-page-description"><p>Questions regarding the placement of cable drums (spools) usually fall into one of two categories.</p>
                  <p>First – “I bought an 81” wide system but my trailer is only 75” wide.&nbsp; Can I cut it down?&nbsp; Where do I re-position the spools?”</p>
                  <p style={{ paddingLeft: 30 }}>Answer – The drums (spools) are never placed exactly where they will be on the tube after installation.&nbsp; We position them at the factory for ease of shipment.&nbsp; The installer always has to position the drums.&nbsp; They should be positioned as far apart as possible, just inside of the door opening so that the cable cannot rub the door frame when the door opens and closes.&nbsp; Placing them as far apart as possible gives the user maximum clearance to load gear &nbsp;without catching the cables.</p>
                  <p>Second – “My system is too long.&nbsp; Can I cut the tube without effecting the performance of the system.</p>
                  <p style={{ paddingLeft: 30 }}>Answer – As the springs are wound to create the tension that lifts the door, they grow longer in length.&nbsp; Space must be available for this growth or the springs will “hump up” as the door opens and closes when they run out of space.&nbsp; This will take lift force from the system and it will so cause premature failure of the system and the door will be too heavy.&nbsp; &nbsp;What is the minimum space needed for the springs to function properly?&nbsp; Here is the formula to calculate minimum tube length:</p>
                  <div className="table-responsive" style={{ maxWidth: "100%", overflow: "auto" }}>
                    <table style={{ width: 615, height: 300, marginLeft: 30 }}>
                      <tbody>
                        <tr style={{ height: 48 }}>
                          <td style={{ width: 541, height: 48 }}>The end bearing assembly, cones, drum spacer, &amp; M-3 drums&nbsp;take 5” on each end (3” and 6” drums are different).</td>
                          <td style={{ width: 62, height: 48 }}>10.00”</td>
                        </tr>
                        <tr style={{ height: 48 }}>
                          <td style={{ width: 541, height: 48 }}>The length of the spring is the last number in the description&nbsp;of the unconed spring&nbsp; (i.e.&nbsp; 160# springs are 26.50”)</td>
                          <td style={{ width: 62, height: 48 }}>26.50”</td>
                        </tr>
                        <tr style={{ height: 72 }}>
                          <td style={{ width: 541, height: 72 }}>The space the spring needs for winding 10 turns is 10 times&nbsp;the spring wire diameter or the first number in the&nbsp;unconed spring description )(160# springs this is .218)</td>
                          <td style={{ width: 62, height: 72 }}>2.18″</td>
                        </tr>
                        <tr style={{ backgroundColor: '#000000', height: 2 }}>
                          <td style={{ width: 541, height: 8 }}></td>
                          <td style={{ width: 62, height: 8 }}></td>
                        </tr>
                        <tr style={{ height: 24 }}>
                          <td style={{ width: 541, height: 24 }}>Tube length needed for the system to work properly*</td>
                          <td style={{ width: 62, height: 24 }}>38.78”</td>
                        </tr>
                        <tr style={{ height: 48 }}>
                          <td style={{ width: 541, height: 48 }}>*(This is on a single-spring system on a dual the spring length and the winding space need to be doubled to get the minimum tube length.)</td>
                          <td style={{ width: 62, height: 48 }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CollapsedContent>
              <CollapsedContent title={"Unusual Trailer Size – Custom Options"}>
                <div className="terms-page-description"><p><strong>Spring weights</strong> – Springs are stocked at M-3 to handle doors with springing weights of 45 lbs through 300 lbs. 205 lbs through 300 lbs systems have a center bearing plate and weld bracket assembly for added support.</p>
                  <p><strong>Drum sizes</strong> – Standard M-3 drums are 4” diameter. Both 3” diameter and 6” diameter drums are available. The 3” drum is often used in trailers with less than 6” of headroom clearance above the door opening. The 3” drum will carry only 150 inches of cable and requires 10 inches to remain on the drum as the safety wrap. The 6” drum is often used in extra tall trailers. The 6” drum will carry 240 inches of cable and requires 29 inches to remain on the drum as the safety wrap.</p>
                  <p><strong>Trailers with extra heavy doors</strong> – If the springing weight of the door exceeds 315 lbs, M-3 recommends you complete our <a href="/data/M-3-Spring-System-Spec-Template.pdf" target="_blank" rel="noopener noreferrer">M-3 Spring System Spec Template</a>.&nbsp; We will determine if there is a spring system that will work for you.&nbsp; Alternatively, if a spring system is not practical, we recommend using an electric winch of the type used in loading boat trailers.</p>
                  <p>For custom ramp door spring needs, we always ask that you complete&nbsp; the <a href="/data/M-3-Spring-System-Spec-Template.pdf" target="_blank" rel="noopener noreferrer">M-3 Spring System Spec Template</a>.&nbsp; &nbsp;This will provide us the data we need to properly size your spring system.&nbsp; Our sophisticated modeling software can handle almost any application that requires ramp door lift assistance.</p>
                </div>
              </CollapsedContent>
            </div>

          </CommonDivWidget>
          <p className="bottom-text">For more information on any of our ramp door spring systems, or for assistance with custom needs, contact at 574-294-3988 or send us an email at&nbsp;<a href="mailto:info@rampdoorsprings.com">info@rampdoorsprings.com</a>.</p>

        </div>
      </div>
    </TempTopMenuWidget>
  );
}

export default TermsPage;
