import { axiosPost } from "./ajaxServices";
import { urlContactUs } from "./CONSTANTS";

export const apiContactUs = (param) => {
  const url = urlContactUs;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("full_name", param.full_name);
  formData.append("contact_info", param.contact_info);
  formData.append("subject", param.subject);
  formData.append("message", param.message);

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
